@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.section {
  display: flex;
  flex-direction: column;
  padding: fn.spacing(6) 0;
}

.carousel-wrapper {
  position: relative;
  width: 100%;
}

.eyebrow,
.eyebrow-only {
  font-size: 14px;
  letter-spacing: 3.5px;
}

.eyebrow {
  padding-bottom: fn.spacing(1);
}

.eyebrow-only {
  padding-bottom: fn.spacing(4);
}

.disclaimer {
  color: var.$color-neutral-gray-eighty;
  padding-top: fn.spacing(4);
  text-align: left;
  max-width: 803px;
  width: 100%;
}

.carousel-item {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: left var.$default-animation-timing, transform var.$default-animation-timing,
    opacity var.$default-animation-timing, filter var.$default-animation-timing;
  filter: brightness(0.7);
  z-index: 0;

  &[data-active='true'] {
    z-index: 2;
    opacity: 1;
    filter: brightness(1);
    // Ascend: Shadows + Blurs: https://www.figma.com/file/tn1r1b5fs14dD0kFLSk4mL/Ascend-Design-System?type=design&node-id=278%3A13348&mode=dev

    /* Shadows/Small */
    box-shadow: 0 1px 1px 0 rgb(16 24 40 / 3%), 0 0.5px 3px 0 rgb(16 24 40 / 9%);
  }

  &[data-previous='true'] {
    z-index: 1;
    left: 25%;
    transform: translateX(-50%) scale(0.85);
    opacity: 1;
  }

  &[data-next='true'] {
    z-index: 1;
    left: 75%;
    transform: translateX(-50%) scale(0.85);
    opacity: 1;
  }
}

.carousel-controls {
  opacity: 0;
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: space-between;
  transform: translateY(-50%);
  top: 50%;
  padding: 0 fn.spacing(2);
  z-index: 1;
}

.carousel-items {
  width: 100%;
  position: relative;
  max-width: 650px;
}

.indicators {
  margin-top: fn.spacing(4);
}

.message {
  opacity: 0;
  z-index: 0;
  height: 0;
  overflow-y: hidden;
  top: 0;

  &[data-active='true'] {
    margin-top: fn.spacing(7);
    opacity: 1;
    z-index: 1;
    height: auto;
  }
}

.testimonial-copy {
  max-width: 803px;
  padding-bottom: 14px;

  strong {
    font-weight: 500;
  }
}

.title-divider {
  margin-bottom: fn.spacing(0);
}

.testimonial-image-container {
  position: relative;
}

.testimonial-image {
  border-radius: var.$achieve-default-border-radius;
  overflow: hidden;
}

@keyframes fade-up {
  from {
    bottom: -10px;
    opacity: 0;
  }

  to {
    bottom: -20px;
    opacity: 1;
  }
}

.testimonial-image-title {
  background: linear-gradient(90deg, #dff7fd 0%, #e4dafb 68.43%, #ddf3ff 95.43%);
  border-radius: 10px;
  bottom: -20px;
  box-shadow: 0 1.6553px 3.3106px 0 rgb(16 24 40 / 5%);
  color: var.$color-primary-trust-dark-blue;
  left: 50%;
  padding: fn.spacing(1) fn.spacing(2);
  position: absolute;
  transform: translateX(-50%);
  white-space: nowrap;
  animation-name: fade-up;
  animation-duration: 0.5s;
}

.carousel-wrapper-indicators button {
  background-color: var.$color-neutral-grey-4;
}

.carousel-wrapper-indicators {
  background-color: transparent !important;
}

.carousel-active {
  // Need !important here and in this file to override the Carousels very specific styles
  background-color: var.$color-primary-background-default !important;
}

/* Medium (large or greater) styles */
@media screen and (min-width: var.$breakpoint-md) {
  .carousel-controls {
    opacity: 1;
  }

  .eyebrow,
  .eyebrow-only {
    font-size: 16px;
  }

  .eyebrow {
    padding-bottom: fn.spacing(2);
  }
}

/* Desktop (large or greater) styles */
@media screen and (min-width: var.$breakpoint-lg) {
  .section {
    padding: fn.spacing(8) 0;
  }

  .carousel-items {
    max-width: initial;
  }
}
